import type { SalesResponse, ParsedData } from 'shared/types';

import { Suspense, useEffect, useMemo } from 'react';
import Head from 'next/head';
import dynamic from 'next/dynamic';
import Error from 'next/error';
import { useRouter } from 'next/router';
// import { usePathname } from 'next/navigation';

import { useAuth, useBook, useBranding, useSales } from 'shared/hooks';
import { generateRandomParameter, ZOOM_SIZE } from 'shared/helpers';

const Sales = dynamic(() => import('shared/pages/Sales'), {
  ssr: false
});

export default function Page(props: { data: SalesResponse }) {
  const {
    data: { salesData, fonts, stripe }
  } = props;

  const { push } = useRouter();
  const {
    updateParentRef,
    setSalesHomePageValues,
    setSalesOrderCount,
    updateAmazonURL,
    setIsShippingForm,
    setHidePagewheel
  } = useSales();
  const { setSalesUser } = useAuth();
  const { setSalesHomePageValues: setSalesHomePageValuesForBook, updateBookMedias } = useBook();
  const { setSalesHomePageValues: setSalesHomePageValuesForBranding } = useBranding();
  // const pathName = usePathname();

  const parsedData = useMemo(() => {
    if (!salesData?.extra_head) return null;
    if (salesData.extra_head['meta']) {
      const parsed: ParsedData = JSON.parse(salesData.extra_head['meta']);
      return parsed;
    }
    return null;
  }, [salesData?.extra_head]);

  useEffect(() => {
    if (salesData?.user) setSalesUser(salesData.user?.full_name, salesData.user?.business_name);
    setSalesHomePageValues(salesData, 'sales');
    setSalesHomePageValuesForBook(salesData);
    setSalesHomePageValuesForBranding(salesData);
    setSalesOrderCount(salesData?.order_count || 0);
    setIsShippingForm(salesData?.is_shipping_form);
    setHidePagewheel(!!salesData?.hide_pagewheel);
    if (salesData?.extra_head?.['amazon_url']) updateAmazonURL(salesData.extra_head['amazon_url']);

    const mockupMedia =
      salesData?.book_media?.map((i) => {
        const file = new File([''], 'filename'); // For Actual Sales Page we only need the URL
        return { name: i.name, file: file, url: i.file };
      }) ?? [];
    updateBookMedias(mockupMedia);

    if (typeof document !== 'undefined' && parsedData) {
      const metaFbPixelId: HTMLMetaElement | null = document.querySelector(
        "meta[name='facebook-pixel-id']"
      );
      const metaFbEvents: HTMLMetaElement | null = document.querySelector(
        "meta[name='facebook-pixel-events']"
      );
      const metaGtmContainerId: HTMLMetaElement | null = document.querySelector(
        "meta[name='google-tag-manager-container-id']"
      );

      if (parsedData.facebookPixel) {
        const { pixelId, events } = parsedData.facebookPixel;

        if (!metaFbPixelId) {
          const meta = document.createElement('meta');
          meta.name = 'facebook-pixel-id';
          meta.content = pixelId;
          document.head.appendChild(meta);
        } else {
          metaFbPixelId.content = pixelId;
        }

        if (!metaFbEvents) {
          const meta = document.createElement('meta');
          meta.name = 'facebook-pixel-events';
          meta.content = events
            .map(
              ({ eventName, parameters }) =>
                `${eventName}${parameters ? `:${JSON.stringify(parameters)}` : ''}`
            )
            .join(',');
          document.head.appendChild(meta);
        } else {
          metaFbEvents.content = events
            .map(
              ({ eventName, parameters }) =>
                `${eventName}${parameters ? `:${JSON.stringify(parameters)}` : ''}`
            )
            .join(',');
        }
      }

      if (parsedData.googleTagManager) {
        if (!metaGtmContainerId) {
          const meta = document.createElement('meta');
          meta.name = 'google-tag-manager-container-id';
          meta.content = parsedData.googleTagManager.containerId;
          document.head.appendChild(meta);
        } else {
          metaGtmContainerId.content = parsedData.googleTagManager.containerId;
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parsedData, salesData?.page_data]);

  if (!salesData || !fonts) {
    return <Error statusCode={404} />;
  }

  return (
    <Suspense fallback={<></>}>
      <Head>
        <title>{salesData?.book_name}</title>
        <link rel="icon" href="/website_favicon.ico" />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        {/* <meta property="og:url" content={pathName || window.location.href} /> */}
        <meta property="og:title" content={salesData?.extra_head?.['title']} />
        <meta property="og:description" content={salesData?.extra_head?.['content']} />
        <meta
          property="og:image"
          content={salesData?.extra_head?.['imageURL'] + generateRandomParameter()}
        />
        {/* <meta property="og:image:width" content="1200" /> */}
        {/* <meta property="og:image:height" content="630" /> */}
        <meta property="og:locale" content={salesData?.extra_head?.['language']} />
        <meta property="og:site_name" content={salesData?.extra_head?.['title']} />

        {/* Twitter */}
        <meta property="twitter:card" content="summary_large_image" />
        {/* <meta property="twitter:url" content={pathName || window.location.href} /> */}
        <meta property="twitter:title" content={salesData?.extra_head?.['title']} />
        <meta property="twitter:description" content={salesData?.extra_head?.['content']} />
        <meta
          property="twitter:image"
          content={salesData?.extra_head?.['imageURL'] + generateRandomParameter()}
        />
        {/* <meta property="twitter:site" content="@YourTwitterHandle" /> */}
        {/* <meta property="twitter:creator" content="@YourTwitterHandle" /> */}

        {/* LinkedIn */}
        <meta property="linkedin:card" content="summary_large_image" />
        <meta property="linkedin:title" content={salesData?.extra_head?.['title']} />
        <meta property="linkedin:description" content={salesData?.extra_head?.['content']} />
        <meta
          property="linkedin:image"
          content={salesData?.extra_head?.['imageURL'] + generateRandomParameter()}
        />

        {/* Pinterest */}
        <meta property="pinterest:description" content={salesData?.extra_head?.['content']} />
        <meta
          property="pinterest:image"
          content={salesData?.extra_head?.['imageURL'] + generateRandomParameter()}
        />

        {/* General */}
        {/* <meta name="keywords" content="" /> */}
        {/* <meta name="author" content="Your Name or Company Name" /> */}
        <meta name="robots" content="index, follow" />
      </Head>
      <main ref={updateParentRef} style={{ zoom: ZOOM_SIZE, fontFamily: fonts.name }}>
        <Sales data={salesData} stripe={stripe} navigate={push} />
      </main>
    </Suspense>
  );
}
